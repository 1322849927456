import React, {useState} from 'react';
import './Testimonial.css';
import {testimonialsData} from '../../data/testimonialsData';
import leftArrow from '../../assets/leftArrow.png';
import rightArrow from '../../assets/rightArrow.png'

const Testimonial = () => {

    const [selected, setSelected] = useState(0);
    const tlength = testimonialsData.length;

  return (
    <div className='Testimonials' id='testimonials'>
        
        {/* left side */}
        <div className='left-t'>

            {/* heading */}
            <span>Testimonials</span>
            <span className='stroke-text'>What they</span>
            <span>say about us</span>

            {/* reviews */}
            <span>
                {testimonialsData[selected].review}
            </span>

            {/* name of reviewrs */}
            <span>
                <span style={{color: 'var(--orange)'}}>
                    {testimonialsData[selected].name}
                </span>{' '}
                - {testimonialsData[selected].status}
            </span>
        </div>
        
        {/* right side */}
        <div className="right-t">

            {/* the orange borders behind the image */}
            <div></div>
            <div></div>
            <img src={testimonialsData[selected].image} alt="" />
            <div className="arrows">
                <img 
                    onClick ={()=>{
                        selected===0
                        ? setSelected(tlength-1)
                        : setSelected((prev) => prev-1);
                    }} src={leftArrow} alt="" />

                <img 
                onClick = {()=>{
                    selected===tlength-1
                    ? setSelected(0)
                    : setSelected((prev) => prev+1)
                }}
                src={rightArrow} alt="" />
            </div>
        </div>

    </div>
  )
}

export default Testimonial