import React from 'react';
import Header from '../Header/Header';
import './Hero.css'
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';
import NumberCounter from 'number-counter';

const Hero = () => {

  
  return (
    <div className='hero' id='home'>
    <div className="blur blur-h"></div>

      {/* left side */}
        <div className='left-h'>

            {/* header and content */}
            <Header/>
            
            {/* the best ad */}
            <div className='the-best-add'>
              <div></div>
              <span>The best fitness club in the town</span>
            </div>

            {/* hero heading */}
            <div className='hero-text'>
              <div>
                <span className='stroke-text'>Shape</span> <span> Your</span>
              </div>
              <div><span>Ideal Body</span></div>

              <div className='paragraph'>
                <span>In here we will help you shape and build your ideal body and live up your life to the fullest.</span>
              </div>
            </div>

            {/* figures */}
            <div className='figures'>
              <div>
                <span>
                  <NumberCounter end={140} start={100} delay='4' preFix='+'/>
                </span>
                <span>expert coaches</span>
              </div>
              <div>
                <span>
                  <NumberCounter end={978} start={938} delay='4' preFix='+'/>
                </span>
                <span>members joined</span>
                </div>
              <div>
                <span>
                  <NumberCounter end={50} start={10} delay='4' preFix='+'/>
                </span>
                <span>Locations nationwide</span>
              </div>
            </div>

            {/* hero buttons */}
            <div className='hero-button'>
              <button className='btn'>Get Started</button>
              <button className='btn'>Learn More</button>
            </div>
        </div>

        {/* right side */}
        <div className='right-h'>
            <button className='btn'>Join Now</button>

            {/* heart rate */}
            <div className='heart-rate'>
              <img src={Heart} alt="" />
              <span>Heart Rate</span><span> 116 bpm</span>
            </div>

            {/* hero images */}
            <img src={hero_image} alt="" className='hero-image' />
            <img src={hero_image_back} alt="" className='hero-image-back' />

            {/* calories */}
            <div className='calories'>
              <img src={Calories} alt="" />
              <div>
                <span>Calories Burned</span><span>220 kCal</span>
              </div>
              
            </div>
        </div>

    </div>
  )
}

export default Hero;